<template>
  <v-row class="w-100 w-full" justify="center">
    <v-col cols="12" sm="6" md="5" lg="4">
      <v-sheet
        color="white"
        elevation="4"
        outlined
        rounded
        class="ma-5 pt-7 pl-9 pr-9 rounded-lg"
      >
        <v-form ref="register" @submit.prevent="register">
          <v-text-field
            v-model="email"
            ref="email"
            clearable
            dense
            :label="$_strings.welcomePage.EMAIL"
            :rules="rules.email"
            class="mb-5 body-2"
            :hint="emailHint"
            persistent-hint
          ></v-text-field>
          <phone-number-format
            v-model="notelp"
            ref="notelp"
            clearable
            dense
            :label="$_strings.welcomePage.TELP"
            :required="true"
            class="body-2 mt-8"
            persistent-hint
          ></phone-number-format>
          <div class="d-flex align-center">
            <v-checkbox v-model="isAgree"></v-checkbox>
            <div class="body-2">
              {{$_strings.welcomePage.ISAGREE}}
              <template>
                <a
                  @click="dialogTermsConditions = true"
                >
                  {{$_strings.welcomePage.TERMS}}
                </a>
              </template>
            </div>
          </div>
          <v-btn rounded large class="background-denim white--text font-weight-bold body-2 w-100" type="submit">
            {{$_strings.welcomePage.REGISTRATION}}
          </v-btn>
        </v-form>
        <div class="text-center grey--text mt-10">
          <p class="text-scorpion caption" >{{$_strings.welcomePage.INFO_REGISTRATION}}</p>
        </div>
      </v-sheet>
      <dialog-terms-conditions :visible="dialogTermsConditions" @setDialog="setDialog"/>
      <welcome-footer/>
    </v-col>
  </v-row>
</template>

<script>
import WelcomeFooter from './WelcomeFooter.vue';

export default {
  name: 'registration',
  components: {
    WelcomeFooter,
  },
  data() {
    return {
      email: '',
      notelp: '',
      emailHint: '',
      isAgree: false,
      rules: {
        email: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.EMAIL);
            }
            return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
          },
        ],
      },
      dialogTermsConditions: false,
      formHasErrors: false,
    };
  },
  methods: {
    register() {
      const valid = this.$refs.register.validate();
      if (valid) {
        if (!this.isAgree) {
          return this.$dialog.notify.warning('Silahkan menyetujui syarat dan ketentuan untuk memproses.');
        }
        this.$root.$loading.show();
        this.$_services.auth.register(this.email, this.notelp)
          .then((res) => {
            this.$dialog.notify.success('Sistem telah mengirimkan sebuah email. Silahkan cek kotak masuk anda untuk aktivasi.');
          })
          .finally((e) => {
            this.$root.$loading.hide();
            this.$router.go(-1);
          });
      }
    },
    clearRulesEmail() {
      this.emailHint = this.$_strings.welcomePage.EMAIL_HINT;
      this.rules.email = [];
    },
    blurRulesEmail() {
      this.emailHint = '';
      const rulesEmail = [
        (v) => {
          if (v) {
            return true;
          }
          return this.$_strings.messages.validation.REQUIRED('Email');
        },
        (value) => {
          if (this.isEmailValid(value)) {
            return true;
          }
          return this.$_strings.messages.validation.EMAIL;
        },
      ];
      this.rules.email = rulesEmail;
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title, .title sub{
  color: $--color-doveGray;
  font-size: 16px !important;
}
</style>
